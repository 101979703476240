import type { NextPage } from "next";
import Head from "next/head";
import Hero from "../components/Hero/Hero";
import mixpanel from "mixpanel-browser";
import { auth } from "../utils/Authentication";
import { useEffect, useState } from "react";
import { publicIpv4 } from "public-ip";
import { app } from "../initFirebase";
import PriceTable from "../components/Pricing/PriceTable";
import { FAQ } from "../components/Core/FAQ";
import { Card, CardContent } from "../components/ui/card";

app;
const Home: NextPage = () => {
  const [ipAddress, setIpAddress] = useState("");
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY ?? "");

  const checkMixpanel = () => {
    if (auth.currentUser?.uid) {
      mixpanel.identify(auth.currentUser?.uid);

      mixpanel.people.set({
        $email: auth.currentUser?.email,
        $name: auth.currentUser?.displayName,
        $created: auth.currentUser?.metadata.creationTime,
        $last_login: auth.currentUser?.metadata.lastSignInTime,
      });
    } else {
      mixpanel.identify(ipAddress);
    }
  };

  const getIpAddress = async () => {
    try {
      const res = await publicIpv4();
      setIpAddress(res);
    } catch (error) {
      console.error("Failed to get IP address:", error);
    }
  };

  useEffect(() => {
    getIpAddress();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      checkMixpanel();
    });

    return () => unsubscribe();
  }, [ipAddress]);

  return (
    <>
      <Head>
        <title>Professional Text Generator & Excel Formula Tools | ProfessionalizeItTo.me</title>
        <meta
          name="description"
          content="Transform your professional workflow with our AI-powered text generator and Excel formula builder. Create professional content, formulas, and documents instantly. Free tools available."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="professional text generator, formula generator, excel formula generator, professional text converter, professioneller texter, professional writer generator" />
        <link rel="alternate" hrefLang="en" href="https://professionalizeitto.me/" />
        <link rel="alternate" hrefLang="de" href="https://professionalizeitto.me/de" />
        <meta property="og:title" content="Professional Text Generator & Excel Formula Tools" />
        <meta property="og:description" content="Transform your professional workflow with our AI-powered text generator and Excel formula builder. Create professional content, formulas, and documents instantly." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://professionalizeitto.me/" />
      </Head>
      <div className="min-h-screen">
        <div className="mx-auto px-4">
        <Hero />
        <Card className="mt-8">
          <CardContent className="p-6">
            <PriceTable />
          </CardContent>
        </Card>
        <Card className="my-8">
          <CardContent className="p-6">
            <FAQ />
          </CardContent>
        </Card>

        <div className="sr-only" itemScope itemType="http://schema.org/SoftwareApplication">
          <h1 itemProp="name">Professional Text Generator & Excel Formula Tools</h1>
          
          <meta itemProp="applicationCategory" content="BusinessApplication" />
          <meta itemProp="operatingSystem" content="Any" />
          
          <div itemProp="aggregateRating" itemScope itemType="http://schema.org/AggregateRating">
            <meta itemProp="ratingValue" content="4.8" />
            <meta itemProp="ratingCount" content="127" />
            <meta itemProp="reviewCount" content="127" />
          </div>

          <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
            <meta itemProp="price" content="0" />
            <meta itemProp="priceCurrency" content="USD" />
            <link itemProp="availability" href="http://schema.org/InStock" />
          </div>
          
          <div itemProp="description">
            <p>Transform your professional communication with our AI-powered text generator and Excel formula builder. Create professional content, business documents, and complex Excel formulas instantly with our intelligent tools.</p>

            <h2>Professional Text Generation Made Simple</h2>
            <p>Our advanced text generator helps professionals create high-quality content for business communications, marketing materials, and professional documents. Whether you need professional emails, business reports, or marketing copy, our AI technology delivers polished, professional results.</p>

            <div itemScope itemType="http://schema.org/ItemList">
              <h2>Excel Formula Generator</h2>
              <p itemProp="itemListElement">Create complex Excel formulas effortlessly with our formula generator. Perfect for business professionals, data analysts, and Excel users of all levels. Generate formulas for calculations, data analysis, and reporting with our intelligent assistant.</p>

              <h2>Professional Text Converter</h2>
              <p itemProp="itemListElement">Convert and polish your text content to maintain professional standards across all your business communications. Our text converter helps you maintain consistency and professionalism in all your written content.</p>

              <h2>Professional Writing Tools</h2>
              <p itemProp="itemListElement">Access a comprehensive suite of professional writing tools designed to enhance your business communications. From email templates to document formatting, our platform helps you maintain professional standards in all your written content.</p>
            </div>

            <h2>Key Features</h2>
            <ul>
              <li>Professional text generation for business communications</li>
              <li>Excel formula generator for complex spreadsheet calculations</li>
              <li>Multiple language support including English and German</li>
              <li>Professional document formatting and conversion</li>
              <li>Free tools available for essential business needs</li>
            </ul>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Home;
